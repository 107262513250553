import * as React from 'react';
// @ts-ignore
import * as styles from './FormSubmit.module.css';

interface IFormSubmit {
    label: string;
    disabled?: boolean;
    className?: string;
}

const FormSubmit = ({
    label, disabled, className,
}: IFormSubmit) => {
    return <button
        type="submit"
        disabled={disabled}
        className={`${styles.formSubmit} ${className}`}
        data-test-id={`submit__${label.toLowerCase()}`}
    >
        {label}
    </button>;
};

export default FormSubmit;
