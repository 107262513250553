import * as React from 'react';
import {
    Form, Formik
} from 'formik';
import {
    storeNewPassword
} from '../../../api/user';
import {
    useState
} from 'react';
import {
    isBrowser
} from '../../../helpers/browser';
import {
    Link
} from 'gatsby';
import DefaultPage from '../../../components/layouts/DefaultPage';
import EmailInput from '../../../components/formElements/EmailInput/EmailInput';
import PasswordInput from '../../../components/formElements/PasswordInput/PasswordInput';
import FormSubmit from '../../../components/formElements/FormSubmit/FormSubmit';
// @ts-ignore
import * as styles from '../../../components/UnauthenticatedContent/UnauthenticatedContent.module.css';


const passwordMinLength = 12;

interface IFormErrors {
    email: string,
    password: string,
    password_confirmation: string,
}

// markup
const PasswordResetTokenPage = () => {
    const [error, setError,] = useState(null);
    const [success, setSuccess,] = useState(false);

    const phase = 'jury';

    if (success) {
        return <DefaultPage
            phase={process.env.GATSBY_PHASE}
            hideHelpMenu>
            {
                'jury' === phase &&
                <p>
                    Ihr Passwort wurde erfolgreich geändert. Bitte <Link to="/jury/login">melden Sie sich erneut an.</Link>
                </p>
            }
            {
                'registration' === phase &&
                <p>
                    Ihr Passwort wurde erfolgreich geändert. Bitte <Link to="/user/login-register">melden Sie sich erneut an.</Link>
                </p>
            }
        </DefaultPage>;
    }

    return (
        <DefaultPage
            phase={process.env.GATSBY_PHASE}
            hideHelpMenu>
            <div className={'grid-8--desktop grid-offset-2--desktop grid-8--tablet grid-2--mobile'}>
                <h1 className={styles.headline}>Neues Passwort speichern</h1>
            </div>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    password_confirmation: '',
                }}
                validate={values => {
                    const errors = {} as IFormErrors;
                    // Password error
                    if (values.password.length < passwordMinLength) {
                        errors.password = `Password needs to be at least ${passwordMinLength} chars long`;
                    }
                    if (values.password.includes(values.email)) {
                        errors.password = 'Password must not contain email';
                    }
                    // TODO: better password checks
                    // Password confirmation error
                    if (values.password !== values.password_confirmation) {
                        errors.password_confirmation = 'Passwords don\'t match';
                    }
                    // Email error
                    if (!values.email) {
                        errors.email = 'Required';
                    } if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Ungültige E-Mail Adresse';
                    }
                    return errors;
                }}
                onSubmit={(
                    values, {
                        setSubmitting,
                    }
                ) => {
                    (async () => {
                        const result = await storeNewPassword(
                            values.email,
                            values.password,
                            values.password_confirmation,
                            isBrowser() ? window.location.search.replace(
                                '?token=',
                                ''
                            ) : ''
                        );
                        setSubmitting(false);
                        if (!result.success) {
                            setError(result.status);
                        } else {
                            setSuccess(true);
                        }
                        // setEmailSent(true);
                    })();
                }}
            >
                {({
                    isSubmitting,
                    values,
                }) => {
                    return (
                        <main className="grid-2--mobile grid-8--tablet grid-12--desktop">
                            <Form>
                                <EmailInput
                                    label="E-Mail"
                                    name="email"
                                    value={values.email}/>
                                <PasswordInput
                                    label="Passwort"
                                    name="password"
                                    value={values.password}/>
                                <PasswordInput
                                    label="Passwort wiederholen"
                                    name="password_confirmation"
                                    value={values.password_confirmation}/>
                                {error && <div>{error}</div>}
                                <FormSubmit
                                    label="Passwort speichern"
                                    disabled={isSubmitting}
                                />
                                <br/>
                                <br/>
                            </Form>
                        </main>
                    );
                }}
            </Formik>
        </DefaultPage>
    );
};

export default PasswordResetTokenPage;
