import * as React from 'react';
import {
    ErrorMessage, Field, useFormikContext
} from 'formik';
// @ts-ignore
import * as styles from './EmailInput.module.css';
import {
    BaseSyntheticEvent
} from 'react';

interface IEmailInput {
    label: string,
    value?: string,
    name: string,
    // eslint-disable-next-line no-unused-vars
    onBlur?: (event: BaseSyntheticEvent) => void,
    defaultValue?: string,
    testId?: string
}

const EmailInput = ({
    label, value, name, onBlur, defaultValue, testId,
}: IEmailInput) => {
    const {
        setFieldTouched, validateField, errors,
    } = useFormikContext();
    const handleUpdate = (e) => {
        setFieldTouched(name);
        validateField(name);
        if (!errors[name] && onBlur) {
            onBlur(e);
        }
    };
    return <div className={styles.emailInput}>
        <label>
            <span>{label}:</span>
            <ErrorMessage
                className="invalid"
                name={name}
                component="div" />
            <Field
                data-test-id={testId}
                type="email"
                name={name}
                value={value}
                onBlur={handleUpdate}
                defaultValue={defaultValue}
            />
        </label>
    </div>;
};

export default EmailInput;
